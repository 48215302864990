import {
    Document,
    Field,
    FieldMode,
    FieldTypes,
    FormRecipientType,
    PdfDimensions,
    RecipientSettings,
} from '@/types/types';
import { isNonEmptyString } from '@/utils/text';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useAuth } from './Auth';

export interface RequestContextValue {
    document: Document | null;
    setDocument: Dispatch<SetStateAction<Document | null>>;
    signInOrder: boolean;
    setSignInOrder: Dispatch<SetStateAction<boolean>>;
    templateId: string;
    setTemplateId: Dispatch<SetStateAction<string>>;
    recipients: string[];
    setRecipients: Dispatch<SetStateAction<string[]>>;
    recipientsTypes: FormRecipientType[];
    setRecipientsTypes: Dispatch<SetStateAction<FormRecipientType[]>>;
    recipientsNames: string[];
    setRecipientsNames: Dispatch<SetStateAction<string[]>>;
    recipientsSettings: RecipientSettings[];
    setRecipientsSettings: Dispatch<SetStateAction<RecipientSettings[]>>;
    handleRecipientTypeChange: (
        index: number,
        value: FormRecipientType
    ) => void;
    addRecipient: () => void;
    emailMessage: string;
    setEmailMessage: Dispatch<SetStateAction<string>>;
    pdfDimensions: PdfDimensions;
    setPdfDimensions: Dispatch<SetStateAction<PdfDimensions>>;
    fields: Field[];
    setFields: Dispatch<SetStateAction<Field[]>>;
    addField: (field: Partial<Field>) => void;
    updateField: (newData: Partial<Field>) => void;
    selectField: (id: number | string) => void;
    unSelectAll: () => void;
    removeRecipient: (index: number) => void;
    removeField: (id: number | string) => void;
    removeSelectedField: () => void;
    assignRecipientToField: (id: number | string, recipient: string) => void;
    selectedField: Field | undefined;
    reset: () => void;
    nonEmptyRecipients: string[];
    resetFields: () => void;
    resetFile: () => void;
    setTags: Dispatch<SetStateAction<string[]>>;
    tags: string[];
    setMergedFileNames: Dispatch<SetStateAction<string[]>>;
    mergedFileNames: string[];
    userSigUrl: string | null;
    setUserSigUrl: Dispatch<SetStateAction<string | null>>;
    unshiftRecipient: (email: string) => void;
    removeRecipientFields: (index: number) => void;
}

// @ts-expect-error
const RequestContext = React.createContext<RequestContextValue>();

interface ProviderProps {
    children: React.ReactNode;
}

const initialField: Field = {
    id: 0,
    type: FieldTypes.SIGNATURE,
    y: 0,
    x: 0,
    width: 0,
    height: 0,
    pageNum: 1,
    isSelected: false,
    recipientIndex: null,
    description: null,
    mode: FieldMode.FIELD,
    signature: null,
};

const defaultRecipientType = 'EMAIL';

export const defaultRecipientSetting: RecipientSettings = Object.freeze({
    oneClickSign: false,
    videoId: false,
});

export const RequestProvider = ({ children }: ProviderProps) => {
    const [document, setDocument] = useState<Document | null>(null);
    const [mergedFileNames, setMergedFileNames] = useState<string[]>([]);
    const [signInOrder, setSignInOrder] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<string>('');

    const [recipients, setRecipients] = useState<string[]>(['']);
    const [recipientsTypes, setRecipientsTypes] = useState<FormRecipientType[]>(
        [defaultRecipientType]
    );
    const [recipientsNames, setRecipientsNames] = useState(['']);
    const [recipientsSettings, setRecipientsSettings] = useState<
        RecipientSettings[]
    >([{ ...defaultRecipientSetting }]);

    const [emailMessage, setEmailMessage] = useState('');

    const [fields, setFields] = useState<Field[]>([]);
    const fieldIdRef = useRef(0);

    const [tags, setTags] = useState<string[]>([]);

    const [pdfDimensions, setPdfDimensions] = useState<PdfDimensions>({});

    const [userSigUrl, setUserSigUrl] = useState<string | null>(null);

    const { user } = useAuth();

    const addRecipient = useCallback(() => {
        setRecipients((prev) => prev.concat(['']));
        setRecipientsTypes((prev) => prev.concat(defaultRecipientType));
        setRecipientsNames((prev) => prev.concat(['']));
        setRecipientsSettings((prev) =>
            prev.concat([{ ...defaultRecipientSetting }])
        );
    }, []);

    const removeField = useCallback((id: number | string) => {
        setFields((prev) => {
            const copy = [...prev];
            const fieldIndex = copy.findIndex((field) => field.id === id);
            copy.splice(fieldIndex, 1);

            return copy;
        });
    }, []);

    const removeSelectedField = () => {
        const id = fields.find((f) => f.isSelected === true)?.id;

        if (typeof id === 'number' || typeof id === 'string') {
            removeField(id);
        }
    };

    const assignRecipientToField = (id: number | string, recipient: string) => {
        updateField({ id, recipientIndex: recipients.indexOf(recipient) });
    };

    const addField = useCallback((field: Partial<Field>) => {
        setFields((prev) => {
            const copy = [...prev];
            copy.push({
                ...initialField,
                ...field,
                id: fieldIdRef.current,
                // width: initialFieldSize[field.type!].width,
                // height: initialFieldSize[field.type!].height,
            });
            fieldIdRef.current++;
            return copy;
        });
    }, []);

    const updateField = useCallback(
        ({ id, ...newFieldData }: Partial<Field>) => {
            setFields((prev) => {
                const copy = [...prev];
                const fieldIndex = copy.findIndex((f) => f.id === id);
                copy[fieldIndex] = { ...copy[fieldIndex], ...newFieldData };
                return copy;
            });
        },
        []
    );

    const unshiftRecipient = useCallback(
        (email: string) => {
            setRecipients((prev) => {
                const copy = [...prev];
                copy.unshift(email);
                return copy;
            });

            setRecipientsTypes((prev) => {
                const copy = [...prev];
                copy.unshift('EMAIL');
                return copy;
            });

            setRecipientsNames((prev) => {
                const copy = [...prev];
                copy.unshift('');
                return copy;
            });

            setRecipientsSettings((prev) => {
                const copy = [...prev];
                copy.unshift({ ...defaultRecipientSetting });
                return copy;
            });

            fields.forEach(({ id, recipientIndex }) => {
                if (typeof recipientIndex !== 'number') return;

                updateField({ id, recipientIndex: recipientIndex + 1 });
            });
        },
        [fields, updateField]
    );

    const removeRecipientFields = useCallback(
        (index: number) => {
            const ids = fields
                .filter((f) => f.recipientIndex === index)
                .map((f) => f.id);

            ids.forEach((id) => removeField(id));
        },
        [fields, removeField]
    );

    const removeRecipient = useCallback(
        (index: number) => {
            const removeAtIndex = (prev: any) => {
                const copy = [...prev];
                copy.splice(index, 1);
                return copy;
            };

            setRecipients(removeAtIndex);
            setRecipientsTypes(removeAtIndex);
            setRecipientsNames(removeAtIndex);
            setRecipientsSettings(removeAtIndex);

            fields.forEach(({ id, recipientIndex }) => {
                if (typeof recipientIndex !== 'number') return;

                if (recipientIndex === index) {
                    removeField(id);
                }

                if (recipientIndex > index) {
                    updateField({ id, recipientIndex: recipientIndex - 1 });
                }
            });
        },
        [fields, removeField, updateField]
    );

    const unSelectAll = useCallback(() => {
        setFields((prev) => {
            const copy = [...prev];

            copy.forEach((field) => (field.isSelected = false));
            return copy;
        });
    }, []);

    const selectField = (id: number | string) => {
        // unSelectAll();
        // updateField({ id, isSelected: true });

        setFields(
            fields.map((field) => ({
                ...field,
                isSelected: field.id === id ? true : false,
            }))
        );
    };

    const handleRecipientTypeChange = useCallback(
        (index: number, value: FormRecipientType) => {
            setRecipientsTypes((prev) => {
                const copy = [...prev];
                copy[index] = value;
                return copy;
            });

            if (value === 'EMAIL') {
                setRecipientsNames((prev) => {
                    const copy = [...prev];
                    copy[index] = '';
                    return copy;
                });
            }
        },
        []
    );

    const resetFields = () => {
        setFields([]);
    };

    const resetFile = () => {
        setDocument(null);
    };

    const resetRecipients = useCallback(() => {
        setRecipients(['']);
        setRecipientsTypes([defaultRecipientType]);
        setRecipientsNames(['']);
        setRecipientsSettings([{ ...defaultRecipientSetting }]);
    }, []);

    const reset = useCallback(() => {
        resetFile();
        resetFields();
        setSignInOrder(false);
        resetRecipients();
        setEmailMessage('');
        setPdfDimensions({});
        setUserSigUrl(null);
        setTags([]);
        setMergedFileNames([]);
        setTemplateId('');
    }, [resetRecipients]);

    useEffect(() => {
        if (!user) {
            reset();
        }
    }, [reset, user]);

    useEffect(() => {
        if (
            recipients.length !== recipientsNames.length ||
            recipientsNames.length !== recipientsTypes.length
        ) {
            resetRecipients();
        }
    }, [
        recipients.length,
        recipientsNames.length,
        recipientsTypes.length,
        resetRecipients,
    ]);

    const selectedField = fields.find((field) => field.isSelected);

    const nonEmptyRecipients = recipients.filter((r) => isNonEmptyString(r));

    const value = {
        document,
        setDocument,
        signInOrder,
        setSignInOrder,
        recipients,
        setRecipients,
        emailMessage,
        setEmailMessage,
        fields,
        setFields,
        addField,
        updateField,
        selectField,
        unSelectAll,
        removeRecipient,
        removeField,
        removeSelectedField,
        assignRecipientToField,
        selectedField,
        reset,
        nonEmptyRecipients,
        pdfDimensions,
        setPdfDimensions,
        resetFields,
        resetFile,
        setTags,
        tags,
        userSigUrl,
        setUserSigUrl,
        unshiftRecipient,
        removeRecipientFields,
        recipientsTypes,
        handleRecipientTypeChange,
        addRecipient,
        setRecipientsTypes,
        recipientsNames,
        setRecipientsNames,
        recipientsSettings,
        setRecipientsSettings,
        setMergedFileNames,
        mergedFileNames,
        templateId,
        setTemplateId,
    };

    return (
        <RequestContext.Provider value={value}>
            {children}
        </RequestContext.Provider>
    );
};

export const useRequestContext = () => {
    const context = React.useContext(RequestContext);
    if (context === undefined) {
        throw new Error(
            'useRequestContext must be used within a RequestProvider'
        );
    }
    return context;
};
