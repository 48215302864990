import { InitialFieldSizes } from '@/types/types';
import React, {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useRef,
    useState,
} from 'react';
import { useAuth } from './Auth';
import { useRequestContext } from './Request';

export interface Banner {
    id?: number;
    type: 'error' | 'success';
    message?: string;
}

export interface MainContextValue {
    banners: BannerState[];
    setBanner: (banner: Banner) => void;
    closeBanner: (id: number) => void;
    resetBanners: () => void;
    initialFieldSizesRef: MutableRefObject<InitialFieldSizes | undefined>;
    goToAccount: boolean;
    setGoToAccount: Dispatch<SetStateAction<boolean>>;
    signOut: () => void;
    isFullScreenLoaderVisible: boolean;
    setIsFullScreenLoaderVisible: Dispatch<SetStateAction<boolean>>;
}

export type BannerState = Banner & { id: number };

// @ts-expect-error
const MainContext = React.createContext<MainContextValue>();

interface ProviderProps {
    children: React.ReactNode;
}

let id = 0;

export const MainProvider = ({ children }: ProviderProps) => {
    const { signOut: authSignOut } = useAuth();
    const { reset: resetRequest } = useRequestContext();

    const [banners, setBanners] = useState<BannerState[]>([]);
    const [goToAccount, setGoToAccount] = useState(false);

    const [isFullScreenLoaderVisible, setIsFullScreenLoaderVisible] =
        useState(false);

    const initialFieldSizesRef = useRef<InitialFieldSizes>();

    const setBanner = useCallback(({ type, message }: Omit<Banner, 'id'>) => {
        const _id = id;
        setBanners((prev) => [...prev, { id: _id, type, message }]);

        setTimeout(() => {
            closeBanner(_id);
        }, 5000);

        id++;
    }, []);

    const closeBanner = (id: number) => {
        setBanners((prev) => {
            let next = prev;
            if (prev.find((banner) => banner.id === id)) {
                next = prev.filter((banner) => banner.id !== id);
            }

            return next;
        });
    };

    const resetBanners = () => setBanners([]);

    const signOut = useCallback(() => {
        authSignOut();
        resetRequest();
    }, [authSignOut, resetRequest]);

    const value = {
        banners,
        setBanner,
        closeBanner,
        resetBanners,
        initialFieldSizesRef,
        goToAccount,
        setGoToAccount,
        signOut,
        isFullScreenLoaderVisible,
        setIsFullScreenLoaderVisible,
    };

    return (
        <MainContext.Provider value={value}>{children}</MainContext.Provider>
    );
};

export const useMainContext = () => {
    const context = React.useContext(MainContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
