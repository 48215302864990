import { DEFAULT_LANGUAGE, APP_DOMAIN } from '@/constants';
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from 'firebase/auth';

const config = {
    apiKey: 'AIzaSyA6AugcUWncmVSll1QqxPldEBjV0lrWxT4',
    authDomain: APP_DOMAIN,
    projectId: 'semn-dev',
    storageBucket: 'semn-dev.appspot.com',
    messagingSenderId: '1067797628503',
    appId: '1:1067797628503:web:effd3570ca7d5e89a50512',
    measurementId: 'G-YHP1FBJ2DT',
};

export const app = initializeApp(config);

export const firebaseAuth = getAuth(app);

// firebaseAuth.languageCode = 'en';

firebaseAuth.useDeviceLanguage();

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');
googleProvider.setDefaultLanguage(DEFAULT_LANGUAGE);
googleProvider.setCustomParameters({ prompt: 'select_account' });
// googleProvider.setDefaultLanguage('fr');
export const signInWithGoogle = () =>
    signInWithPopup(firebaseAuth, googleProvider);

export const createFirebaseUser = (email: string, password: string) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
};

export const loginFirebaseUser = (email: string, password: string) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const sendFbPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(firebaseAuth, email);
};
